<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             body-class="pb-0"
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-4">
            <strong v-if="!chat">
                Create chat/group
            </strong>
            <strong v-else>
                Update chat/group
            </strong>
        </h1>

        <b-form v-on:submit.prevent="submit"
                v-on:reset="reset">
            <div class="form-row">
                <b-form-group class="col-md-12"
                              label-for="name">
                    <template v-slot:label>
                        📛 Name <small class="text-danger">* Required</small>
                    </template>
                    <b-form-input id="name"
                                  class="rounded-pill"
                                  name="name"
                                  type="text"
                                  required
                                  v-model="name">
                    </b-form-input>
                </b-form-group>

                <b-form-group class="col-md-6"
                              label-for="type">
                    <template v-slot:label>
                        ⛓️ Type <small class="text-danger">* Required</small>
                    </template>
                    <b-form-select id="type"
                                   class="rounded-pill"
                                   name="type"
                                   required
                                   v-model="type">
                        <option v-bind:value="null">
                            Please select connection target
                        </option>
                        <option value="whatsapp">
                            WhatsApp
                        </option>
                        <option value="telegram">
                            Telegram
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group class="col-md-6"
                              label-for="tags">
                    <template v-slot:label>
                        🏷️ Categories <small class="text-danger">* Required</small>
                    </template>
                    <b-form-tags id="tags"
                                 class="rounded-pill"
                                 v-model="tags">
                    </b-form-tags>
                </b-form-group>

                <b-form-group class="col-md-12"
                              label-for="link">
                    <template v-slot:label>
                        🔗 Link <small class="text-danger">* Required</small>
                    </template>
                    <b-form-input id="link"
                                  class="rounded-pill"
                                  name="link"
                                  type="text"
                                  required
                                  v-model="link">
                    </b-form-input>
                </b-form-group>

                <b-form-group class="col-12"
                              label-for="description">
                    <template v-slot:label>
                        🗄️ Description
                    </template>
                    <b-form-textarea id="description"
                                     name="description"
                                     class="rounded-xl"
                                     v-model="description">
                    </b-form-textarea>
                </b-form-group>
            </div>

            <div class="position-sticky buttons text-center py-3">
                <button type="submit"
                        class="btn btn-primary rounded-pill shadow-sm px-4 mr-2"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
                <button type="reset"
                        class="btn btn-secondary rounded-pill shadow-sm px-4"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BFormTags, } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
    name: "ModalChat",
    components: {
        BModal, BForm, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BFormTags,
        "v-select": vSelect,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "chat": {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,

            name: "",
            type: null,
            description: "",
            participants: [],
            tags: [],
        };
    },
    computed: {
        users () {
            return this.$store.getters["data/people"];
        },
    },
    methods: {
        hideModal() {
            this.$emit("input", false);
        },
        getKey ({ id }) {
            return id;
        },
        getLabel ({ name, countryCode, phone, }) {
            return `${ name } (+${ countryCode } ${ phone })`;
        },
        reset() {
            this.name = "";
            this.type = null;
            this.description = "";
            this.participants = [];
            this.tags = [];
        },
        async submit () {
            if (this.chat) {
                return this.edit();
            }
            return this.add();
        },
        async add () {
            const { name, type, description, participants, tags, } = this;
            this.$store.commit(
                "data/addChat",
                {
                    name, type, description, participants, tags,
                }
            );
            this.reset();
            this.hideModal();
        },
        async edit () {
            const {
                chat: { id },
                name, type, description, participants, tags,
            } = this;
            this.$store.commit(
                "data/editChat",
                {
                    id,
                    name, type, description, participants, tags,
                }
            );
            this.hideModal();
        },
    },
    mounted () {
        if (this.chat) {
            const { name, type, description, participants, tags, } = this.chat;
            this.name = name;
            this.type = type;
            this.description = description;
            this.participants = participants;
            this.tags = tags;
        }
    },
    watch: {
        chat (newVal) {
            if (newVal) {
                const { name, type, description, participants, tags, } = newVal;
                this.name = name;
                this.type = type;
                this.description = description;
                this.participants = participants;
                this.tags = tags;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.buttons {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3
}
</style>
